import React from "react";

import BtnLoading from "@components/loader/BtnLoading";

const variants = {
  green:
    "arh-bg-greenColor arh-border-greenColor arh-text-white hover:arh-bg-darkGreenColor hover:arh-border-darkGreenColor disabled:arh-border-greyDisabled",
  outlined:
    "arh-bg-white arh-border-greyBorderBtn arh-text-black hover:arh-bg-greyDisabled active:arh-bg-greyActive disabled:arh-border-greyDisabledBorder",
  "green-outlined":
    "arh-group/outlined arh-bg-cardGreen arh-border-greenColor arh-text-greenColor hover:arh-bg-lightGreenHover hover:arh-border-lightGreenHover hover:arh-text-textBlackBtnHover disabled:arh-border-greyDisabledBorder",
  orange:
    "arh-bg-orangeColor arh-border-orangeColor arh-text-white hover:arh-bg-loaderOrange hover:arh-border-loaderOrange disabled:arh-border-greyDisabled",
  "orange-outlined":
    "arh-group/outlined arh-bg-lightOrange arh-border-orangeColor arh-text-darkOrange hover:arh-bg-orangeColor hover:arh-border-orangeColor hover:arh-text-textBlackBtnHover disabled:arh-border-greyDisabled",
  blue: "arh-bg-loaderBlue arh-border-loaderBlue arh-text-white hover:arh-bg-blueColor hover:arh-border-blueColor disabled:arh-border-greyDisabled",
  darkBlue:
    "arh-bg-blueColor arh-border-blueColor arh-text-white hover:arh-bg-hoverBlueColor hover:arh-border-hoverBlueColor disabled:arh-border-greyDisabled",
  black:
    "arh-bg-black arh-border-black arh-text-white hover:arh-bg-darkerColor hover:arh-border-darkerColor disabled:arh-border-greyDisabled",
  grey: "arh-bg-[#F4F4F4] arh-border-[#BDBDBD] arh-text-[#8A8A8A]",
  babyBlue:
    "arh-bg-[#95C5E7] arh-border-[#D3E3F1] arh-text-white hover:arh-text-[#D3E3F1] arh-border hover:arh-bg-[#1A4E63] hover:arh-border-[#D3E3F1] disabled:arh-border-greyDisabled",
};

/**
 * @typedef Props
 * @property {("green" | "outlined" | "green-outlined" | "orange" | "orange-outlined" | "blue" | "darkBlue" | "black" | "grey" | "babyBlue")=} variant
 * @property {string=} className
 * @property {(event: any) => void=} onClick
 * @property {JSX.Element} children
 * @property {boolean=} disabled
 * @property {JSX.Element=} icon
 * @property {("default" | "left")=} iconPosition
 * @property {boolean=} wait
 * @property {("button" | "submit" | "reset")=} type
 */

/**
 *
 * @param {Props} props
 * @returns
 */
function Button(props) {
  const {
    variant = "green",
    className,
    onClick = null,
    children,
    disabled = false,
    icon,
    iconPosition = "left",
    wait = false,
    type = "button",
  } = props;

  return (
    <button
      type={type}
      className={`${wait && "arh-cursor-wait"} arh-flex arh-w-full arh-items-center arh-justify-center arh-space-x-3 arh-whitespace-nowrap arh-rounded-[5px] arh-border arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-medium arh-tracking-wide arh-transition arh-duration-200 arh-ease-in disabled:arh-cursor-not-allowed disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText desktop-mini:arh-text-[.70rem] ${
        variants[variant]
      } ${className} ${
        iconPosition === "left"
          ? "arh-flex-row"
          : "arh-flex-row-reverse arh-space-x-reverse"
      }`}
      onClick={wait ? null : onClick}
      disabled={disabled}
    >
      <ButtonContent icon={icon} wait={wait}>
        {children}
      </ButtonContent>
    </button>
  );
}

function ButtonContent(props) {
  if (props.wait) {
    return <BtnLoading />;
  }

  return (
    <>
      {props.icon && (
        <i className="group-hover/outlined:arh-mix-blend-difference">
          {props.icon}
        </i>
      )}
      <span>{props.children}</span>
    </>
  );
}

export default Button;
