/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";

import abstractReducer from "../store/reducers/_abstractReducer";
import calendarReducer from "../store/reducers/_calendarReducer";
import commonDataReducer from "../store/reducers/_commonDataReducer";
import connectedEmployeDataReducer from "../store/reducers/_connectedEmployeDataReducer";
import developmentPlanReducer from "../store/reducers/_developmentPlanReducer";
import documentationReducer from "../store/reducers/_documentationReducer";
import enterpriseEmployeDataReducer from "../store/reducers/_enterpriseEmployeDataReducer";
import evaluationReducer from "../store/reducers/_evaluationReducer";
import materialsReducer from "../store/reducers/_materialsReducer";
import newsReducer from "../store/reducers/_newsReducer";
import notificationReducer from "../store/reducers/_notificationReducer";
import onboardingReducer from "../store/reducers/_onboardingReducer";
import organisatorReducer from "../store/reducers/_organisatorReducer";
import recruitmentsReducer from "../store/reducers/_recruitmentsReducer";
import reimbursmentsReducer from "../store/reducers/_reimbursmentsReducer";
import reportsReducer from "../store/reducers/_reportReducer";
import settingsReducer from "../store/reducers/_settingsReducer";
import userConnectedReducer from "../store/reducers/_userConnectedReducer";
import workingHoursReducer from "../store/reducers/_workingHoursReducer";

/**
 * @typedef ReduxState
 * @property {typeof abstractReducer.initialState} abstract
 * @property {typeof calendarReducer.initialState} calendar
 * @property {typeof commonDataReducer.initialState} commonData
 * @property {typeof connectedEmployeDataReducer.initialState} connectedEmployeData
 * @property {typeof documentationReducer.initialState} documentation
 * @property {typeof enterpriseEmployeDataReducer.initialState} enterpriseEmployeData
 * @property {typeof materialsReducer.initialState} materials
 * @property {typeof newsReducer.initialState} news
 * @property {typeof notificationReducer.initialState} notification
 * @property {typeof onboardingReducer.initialState} onboarding
 * @property {typeof recruitmentsReducer.initialState} recruitments
 * @property {typeof settingsReducer.initialState} settings
 * @property {typeof userConnectedReducer.initialState} userConnected
 * @property {typeof reimbursmentsReducer.initialState} reimbursments
 * @property {typeof reportsReducer.initialState} reports
 * @property {typeof workingHoursReducer.initialState} workingHours
 * @property {typeof evaluationReducer.initialState} evaluation
 * @property {typeof developmentPlanReducer.initialState} developmentPlan
 * @property {typeof organisatorReducer.initialState} organisator
 */

/**
 * @template {ReduxState} TState
 * @template TSelected
 * @param {(state: TState) => TSelected} selector - Sélecteur de l'état.
 * @param {(left: TSelected, right: TSelected) => boolean} [equalityFn] - Fonction pour vérifier l'égalité.
 * @returns {TSelected} Le résultat du sélecteur.
 */
export function useReduxSelector(selector, equalityFn) {
  return useSelector(selector, equalityFn);
}
