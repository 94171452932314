import { makeActionHandler } from "./_reducerUtils";

export const organisatorReducer = {
  initialState: {
    organisatorProjects:{
        list:[],
        isLoaded:false
    },
    organisatorTasks:{
        list:{},
        isLoaded:false,
        projectId:null
    },
    organisatorTaskPropreties:{
      list:[],
      isLoaded:false,
      projectId:null
  },
  },
  handlers: {
    "organisator/SET_PROJECTS": makeActionHandler("organisatorProjects"),
    "organisator/SET_TASKS": makeActionHandler("organisatorTasks"),
    "organisator/SET_TASK_PROPERTIES": makeActionHandler("organisatorTaskPropreties"),
    "organisator/RESET_ALL": () => organisatorReducer.initialState,
    RESET_ALL: () => organisatorReducer.initialState,
  },
};

export default organisatorReducer;
